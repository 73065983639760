import { produce } from "immer";
import { useState } from "react";
import Modal from "react-modal";
import { SegmentedControl } from "./SegmentedControl";

export type InviteeDetails = {
  firstName: string;
  lastName: string;

  kind: "child" | "minor" | "adult";

  food: "standard" | "dietaryRestriction";
  foodComment: string;
};

export const Invitee = (props: {
  createMode: boolean;

  details: InviteeDetails;
  onChange: (v: InviteeDetails) => void;
  idx: number;
  onDelete: () => void;
}) => {
  const { details, onChange, createMode } = props;
  const [isEditing, setIsEditing] = useState(createMode);

  const [detailsDraft, setDetailsDraft] = useState<InviteeDetails>(details);

  const isInvalid = (value: string) => {
    return value.trim() === "" || value.length > 50;
  };

  return (
    <>
      {!createMode && (
        <div onClick={() => setIsEditing(true)} className="invitee-summary">
          <div className="name">
            <span className="last-name">{details.lastName}</span>
            &nbsp;
            <span className="first-name">{details.firstName}</span>
            &nbsp;
            <span className="kind">
              ({details.kind === "adult" && "Adult"}
              {details.kind === "minor" && "Minor"}
              {details.kind === "child" && "Copil"})
            </span>
          </div>
          <div className="food">
            {details.food === "standard"
              ? "Meniu standard"
              : "Restricții alimentare"}
            {details.food === "dietaryRestriction" && (
              <>&nbsp;&mdash;&nbsp;{details.foodComment}</>
            )}
          </div>
        </div>
      )}
      <Modal
        isOpen={isEditing}
        onRequestClose={() => setIsEditing(false)}
        className="invitee-sheet-modal-content"
        overlayClassName="invitee-sheet-modal-overlay"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        closeTimeoutMS={300}
      >
        <div className="invitee-edit-sheet">
          <div className="section-title no-margin-top">Detalii invitat</div>
          <input
            name="lastName"
            type="text"
            autoComplete="off"
            placeholder="Nume"
            value={detailsDraft.lastName}
            // className={isInvalid(detailsDraft.lastName) ? "invalid" : ""}
            onChange={(e) => {
              const v = e.target.value;
              setDetailsDraft((ps) =>
                produce(ps, (draft) => {
                  draft.lastName = v;
                })
              );
            }}
          />
          <input
            name="firstName"
            type="text"
            placeholder="Prenume"
            value={detailsDraft.firstName}
            autoComplete="off"
            // className={isInvalid(detailsDraft.firstName) ? "invalid" : ""}
            onChange={(e) => {
              const v = e.target.value;
              setDetailsDraft((ps) =>
                produce(ps, (draft) => {
                  draft.firstName = v;
                })
              );
            }}
          />

          <SegmentedControl
            options={[
              { displayName: "Adult", value: "adult" },
              { displayName: "Minor", value: "minor" },
              { displayName: "Copil", value: "child" },
            ]}
            value={detailsDraft.kind}
            onChange={(v) => {
              setDetailsDraft((ps) =>
                produce(ps, (draft) => {
                  draft.kind = v as any;
                })
              );
            }}
          />
          <div className="section-title">Meniu mâncare</div>
          <SegmentedControl
            options={[
              { displayName: "Standard", value: "standard" },
              {
                displayName: "Restricții alimentare",
                value: "dietaryRestriction",
              },
            ]}
            value={detailsDraft.food}
            onChange={(v) => {
              setDetailsDraft((ps) =>
                produce(ps, (draft) => {
                  draft.food = v as any;
                })
              );
            }}
          />
          {detailsDraft.food === "dietaryRestriction" && (
            <input
              type="text"
              value={detailsDraft.foodComment}
              placeholder="vegeterian, alergii, fără gluten, etc."
              onChange={(e) => {
                const v = e.target.value;
                setDetailsDraft((ps) =>
                  produce(ps, (draft) => {
                    draft.foodComment = v;
                  })
                );
              }}
            />
          )}

          {/* <pre>{JSON.stringify(details, null, 2)}</pre> */}
          <div className="actions">
            <button
              onClick={() => {
                onChange(detailsDraft);
                setIsEditing(false);
              }}
              className="button success"
              disabled={
                isInvalid(detailsDraft.firstName) ||
                isInvalid(detailsDraft.lastName) ||
                (detailsDraft.food === "dietaryRestriction" &&
                  isInvalid(detailsDraft.foodComment))
              }
            >
              Salvează
            </button>
            <button
              className="button"
              onClick={() => {
                if (createMode) {
                  props.onDelete();
                } else {
                  setDetailsDraft(details);
                }
                setIsEditing(false);
              }}
            >
              Anulează
            </button>
            {!createMode && (
              <button
                className="button destructive"
                onClick={() => {
                  props.onDelete();
                  setIsEditing(false);
                }}
              >
                Șterge
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
