import { useEffect, useRef } from "react";

export const ProperVideo = (props: { src: string; className: string }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  // useEffect(() => {
  //   const videoElement = videoRef.current;
  //   if (videoElement) {
  //     videoElement.addEventListener("loadeddata", () => {
  //       requestAnimationFrame(() => {
  //         // Force a second frame to ensure the reflow happens after any browser adjustments
  //         requestAnimationFrame(() => {
  //           void videoElement.offsetHeight;
  //         });
  //       });
  //       setTimeout(() => {
  //         videoElement.parentElement!!.style.minHeight = '10px'
  //         requestAnimationFrame(() => {
  //         videoElement.parentElement!!.style.minHeight = '100lvh'
  //         })

  //       });
  //       setTimeout(() => {
  //         document.body.style.display = 'none';
  //         requestAnimationFrame(() => {
  //           document.body.style.display = '';
  //         });
  //       }, 100);
  //       // setTimeout(() => {
  //       //   const originalWidth = videoElement.style.width;
  //       //   requestAnimationFrame(() => {
  //       //     videoElement.style.width = originalWidth;
  //       //   });
  //       // }, 100);
  //       // setTimeout(() => {
  //       //     void videoElement.offsetHeight;
  //       //   // videoElement.style.height = '100lvh';
  //       // }, 100);
  //     });
  //   }
  // }, []);
  // useEffect(() => {
  //   const onLoad = () => {
  //     requestAnimationFrame(() => {
  //       // Trigger reflow
  //       document.body.style.display = 'none';
  //       requestAnimationFrame(() => {
  //         document.body.style.display = '';
  //       });
  //     });
  //   };
  
  //   window.addEventListener('load', onLoad);
  //   return () => window.removeEventListener('load', onLoad);
  // }, []);

  return (
    <video ref={videoRef} className={props.className} autoPlay loop muted>
      <source src={`${props.src}.webm`} type="video/webm" />
      <source src={`${props.src}.mp4`} type="video/mp4" />
    </video>
  );
};
