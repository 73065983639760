import { Parallax } from "react-scroll-parallax";
import { ProperVideo } from "./ProperVideo";
import { RsvpSection } from "./RsvpSection";

export const App = () => {

  return (
    <>
      <div className="section welcome">
          <ProperVideo src="assets/broll" className="" />
        <div className="overlay">
          <div className="names">
            <Parallax speed={-5}>
              <span>Antonia</span>
              <span> & </span>
              <span>Emil</span>
            </Parallax>
          </div>
          <div className="date">17.08.2024</div>
        </div>
      </div>
      <div className="section invite-text bg1">
        <div className="content">
          <Parallax speed={-5}>
            <p className="title">Dragii noștri,</p>
            <p>
              Suntem încântați să vă invităm să ne fiți alături la celebrarea
              căsătoriei noastre.
            </p>
            <p>
              Ne dorim mult ca această zi să fie una de neuitat, iar prezența
              voastră ar face-o cu adevărat specială.
            </p>
            <p>
              Întrucât cea mai frumoasă bucurie este cea împărtășită, așteptăm
              cu nerăbdare să scriem povestea nunții noastre împreună.
            </p>
          </Parallax>
        </div>
      </div>
      <div className="section church3">
        <div className="right">
          <Parallax speed={-5}>
            <div className="title">Cununia Religioasă</div>
            <div className="details">
              <div>Va avea loc</div>
              <div>
                de la <b>ora 13:30</b>
              </div>
              <br />
              <div>
                <b>Biserica Ortodoxă a Tuturor Sfinților</b>
              </div>
              <div className="address">Strada Ciucaș, Cluj-Napoca</div>
            </div>
            <div className="action">
              <button className="button success" onClick={() => window.open('https://maps.app.goo.gl/TYp67pcBmD6hFbgS6', '_blank')}>Vezi pe hartă</button>
            </div>
          </Parallax>
        </div>
      </div>
      <div className="section party2">
        <div className="right">
          <Parallax speed={-5}>
            <div className="title">Petrecerea</div>
            <div className="details">
              <div>Va avea loc</div>
              <div>
                de la <b>ora 16:00</b>
              </div>
              <br />
              <div>
                <b>SunGarden Resort</b>
              </div>
              <div className="address">
                Strada Poiana Cerbului 1000, România
              </div>
            </div>
            <div className="action">
              <button className="button success" onClick={() => window.open('https://maps.app.goo.gl/fz9mkmGPToYHdKDe6', '_blank')}>Vezi pe hartă</button>
            </div>
          </Parallax>
        </div>
      </div>
      <div className="section invite-text invite-text-2 bg2">
        <div className="content">
          <Parallax speed={-5}>
            <p>
              Pentru a ne ajuta să pregătim totul în timp util, vă rugăm să ne confirmați participarea cât mai curând posibil, folosind formularul de mai jos.
            </p>
            <p>
              Dacă aveți întrebări, nelămuriri sau doriți să ne lăsați un mesaj,
              suntem disponibili la <a href="mailto:hello@ae-wedding.com" style={{ whiteSpace: 'nowrap' }}>hello@ae-wedding.com</a>.
            </p>
            <p>Vă așteptăm cu drag!</p>
          </Parallax>
        </div>
      </div>
      <RsvpSection />
    </>
  );
};
