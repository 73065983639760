import React, { useState } from 'react';

interface GdprCheckboxProps {
  onChange: (isChecked: boolean) => void;
}

const GdprCheckbox: React.FC<GdprCheckboxProps> = ({ onChange }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    onChange(event.target.checked);
  };

  return (
    <div style={{padding: '1rem', display: 'flex', alignItems: 'center'}}>
      <input
        type="checkbox"
        id="gdprCheckbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        style={{marginRight: '0.5rem'}}
      />
      <label htmlFor="gdprCheckbox" style={{flex: 1}}>
        Sunt de acord cu <a href="/gdpr.html" target="_blank" rel="noopener noreferrer">prelucrarea datelor personale</a> conform politicii GDPR.
      </label>
    </div>
  );
};

export default GdprCheckbox;
