export interface Option {
  displayName: string;
  value: string;
}

export const SegmentedControl = (props: {
  options: Option[];
  value: string;
  onChange: (v: string) => void;
}) => {
  return (
    <div className="segmented-control">
      {props.options.map((option) => (
        <button
          key={option.value}
          className={option.value === props.value ? "selected" : ""}
          onClick={() => props.onChange(option.value)}
        >
          {option.displayName}
        </button>
      ))}
    </div>
  );
};
