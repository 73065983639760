import { produce } from "immer";
import { useState } from "react";
import GdprCheckbox from "./GdprCheckbox";
import axios from "axios";

export const RsvpNo = () => {
  const [detailsDraft, setDetailsDraft] = useState<{
    firstName: string;
    lastName: string;
  }>({
    firstName: "",
    lastName: "",
  });

  const [gdprConsent, setGdprConsent] = useState(false);

  const handleGdprChange = (isChecked: boolean) => {
    setGdprConsent(isChecked);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const submitRsvp = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post('https://jrbz7fjpkg.execute-api.us-east-1.amazonaws.com/prod/rsvp', {
        inviteeList: [{
          firstName: detailsDraft.firstName,
          lastName: detailsDraft.lastName,
          kind: "",
          food: "",
          foodComment: ""
        }],
        rsvpAnswer: "no",
        gdprConsent,
      });
      setSuccess(true);
      // Handle response if needed
    } catch (err: any) {
      if (!err.response) { // Network error
        setError('NETWORK_ERROR');
      } else {
        setError(err.message || 'An unexpected error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (error) {
    return (
      <div className="rsvp-no">
        {error === "NETWORK_ERROR" && (
          <p>A intervenit o eroare de retea, verificati conextiunea la Internet.</p>

        )}
        {error !== "NETWORK_ERROR" && (
          <p>A intervenit o eroare si raspunsul nu a fost salvat.</p>
        )}
        <button className="button success" onClick={() => {
          setError(null);
          setSuccess(false);
          setGdprConsent(false);
        }}>Încercați din nou</button>
      </div>
    )
  }

  if (success) {
    return (
      <div className="rsvp-no">
        <div className="section-title">Ne pare rău că nu puteți participa</div>
        <div>Raspunsul a fost trimis.</div>
      </div>
    )
  }

  return (
    <div className="rsvp-no">
      <div className="section-title">Ne pare rău că nu puteți participa</div>
      <input
        name="lastName"
        type="text"
        autoComplete="off"
        placeholder="Nume"
        value={detailsDraft.lastName}
        // className={isInvalid(detailsDraft.lastName) ? "invalid" : ""}
        onChange={(e) => {
          const v = e.target.value;
          setDetailsDraft((ps) =>
            produce(ps, (draft) => {
              draft.lastName = v;
            })
          );
        }}
      />
      <input
        name="firstName"
        type="text"
        placeholder="Prenume"
        value={detailsDraft.firstName}
        autoComplete="off"
        // className={isInvalid(detailsDraft.firstName) ? "invalid" : ""}
        onChange={(e) => {
          const v = e.target.value;
          setDetailsDraft((ps) =>
            produce(ps, (draft) => {
              draft.firstName = v;
            })
          );
        }}
      />
      <GdprCheckbox onChange={handleGdprChange} />

      <button
        className="button success"
        disabled={
          detailsDraft.firstName.trim().length === 0 ||
          detailsDraft.lastName.trim().length === 0 ||
          !gdprConsent ||
          isLoading
        }
        onClick={() => submitRsvp()}
      >
        {isLoading ? 'Se trimite...' : 'Trimite raspunsul'}
      </button>
    </div>
  );
};
