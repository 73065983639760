import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom/client";
import Modal from "react-modal";
import { ParallaxProvider } from "react-scroll-parallax";
import { App } from "./App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

Modal.setAppElement("#root");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const AppWrapper = (props: any) => {
  const [scrollEl, setScrollElement] = useState<HTMLDivElement | undefined>(
    undefined
  );

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      setScrollElement(ref.current);
    }
  }, []);

  return (
    <div className="main" ref={ref}>
      <ParallaxProvider scrollContainer={scrollEl}>
        {props.children}
      </ParallaxProvider>
    </div>
  );
};

root.render(
  <React.StrictMode>
    <AppWrapper>
      <App />
    </AppWrapper>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
