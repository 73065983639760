
import { Invitee, InviteeDetails } from "./Invitee";
import { RsvpNo } from "./RsvpNo";
import { SegmentedControl } from "./SegmentedControl";
import GdprCheckbox from "./GdprCheckbox";
import { useState } from "react";
import { produce } from "immer";
import axios from "axios";

export const RsvpSection = () => {
    const [inviteeList, setInviteeList] = useState<InviteeDetails[]>([]);
    const [isCreating, setIsCreating] = useState(false);

    const [rsvpAnswer, setRsvpAnswer] = useState<"yes" | "no" | null>(null);
    const [gdprConsent, setGdprConsent] = useState(false);


    const handleInviteeChange = (index: number, updatedInvitee: InviteeDetails) => {
        setInviteeList(prevState => produce(prevState, draft => {
            draft[index] = updatedInvitee;
        }));
    };
    const handleInviteeDelete = (index: number) => {
        setInviteeList((prevState) =>
            produce(prevState, (draftState) => {
                draftState.splice(index, 1);
            })
        );
    };
    const handleAddInvitee = () => {
        setIsCreating(true);
        setInviteeList((prevState) =>
            produce(prevState, (draftState) => {
                draftState.push({
                    firstName: "",
                    lastName: "",
                    kind: "adult",
                    food: "standard",
                    foodComment: "",
                });
            })
        );
    };

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);

    const submitRsvp = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await axios.post('https://jrbz7fjpkg.execute-api.us-east-1.amazonaws.com/prod/rsvp', {
                inviteeList,
                rsvpAnswer,
                gdprConsent,
            });
            setSuccess(true);
            // Handle response if needed
        } catch (err: any) {
            if (!err.response) { // Network error
                setError('NETWORK_ERROR');
            } else {
                setError(err.message || 'An unexpected error occurred');
            }
        } finally {
            setIsLoading(false);
        }
    };

    if (success) {
        return (
            <div className="section rsvp">
                <div className="content-w">
                    <div className="content">
                        <div className="success-message" style={{textAlign: 'center'}}>
                            <div><b>Mulțumim pentru confirmare!</b></div> 
                            <br/>
                            <div>
                            Așteptăm cu nerăbdare să vă vedem.
</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="section rsvp">
                <div className="content-w">
                    <div className="content">
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center"}}>
                            {error === "NETWORK_ERROR" && (
                                <p>A intervenit o eroare de retea, verificati conextiunea la Internet.</p>

                            )}
                            {error !== "NETWORK_ERROR" && (
                                <p>A intervenit o eroare si raspunsul nu a fost salvat.</p>
                            )}
                            <button className="button success" onClick={() => {
                                setError(null);
                                setSuccess(false);
                                setGdprConsent(false);
                            }}>Încercați din nou</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="section rsvp">
            <div className="content-w">
                <div className="content">
                    <div className="title">Confirmați participarea</div>
                    <div className="confirm">
                        <SegmentedControl
                            options={[
                                { displayName: "Da", value: "yes" },
                                { displayName: "Nu", value: "no" },
                            ]}
                            value={rsvpAnswer || ""}
                            onChange={(v) => setRsvpAnswer(v as any)}
                        />
                    </div>
                    {rsvpAnswer === "yes" && (
                        <>
                            <div className="section-title">
                                Ne bucurăm să vă avem alături!
                            </div>
                            <div className="section-subtitle">Cine sunt invitații?</div>
                            <div className="invitees">
                                {inviteeList.map((invitee, index) => (
                                    <Invitee
                                        key={index}
                                        details={invitee}
                                        createMode={isCreating && index === inviteeList.length - 1}
                                        onChange={(updatedInvitee: any) => {
                                            handleInviteeChange(index, updatedInvitee);
                                            if (isCreating && index === inviteeList.length - 1) {
                                                setIsCreating(false);
                                            }
                                        }}
                                        idx={index}
                                        onDelete={() => {
                                            handleInviteeDelete(index);
                                            setIsCreating(false);
                                        }}
                                    />
                                ))}
                            </div>
                            <div
                                className={`invitee-summary empty ${inviteeList.length === 0 ? "highlight" : ""
                                    }`}
                                onClick={handleAddInvitee}
                            >
                                Adaugă invitat
                            </div>

                            <br />
                            <br />

                            {inviteeList.length > 0 && !isCreating && (
                                <>
                                    <GdprCheckbox onChange={setGdprConsent} />

                                    <button
                                        className="button success"
                                        disabled={inviteeList.length === 0 || !gdprConsent || isLoading}
                                        onClick={submitRsvp}
                                    >
                                        {isLoading ? "Se trimite..." : `Confirmă participarea a ${inviteeList.length} invitați`}
                                    </button>
                                </>
                            )}
                            <br />
                            <br />
                        </>
                    )}
                    {rsvpAnswer === "no" && <RsvpNo />}
                </div></div>
        </div>
    )
}